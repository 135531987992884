<template>
  <dl
    v-if="vacancy"
    :key="vacancy.ID"
    class="v-vacancy-single"
  >
    <div
      class="v-vacancy-single--title"
      v-html="sanitize(vacancy.Title)"
    />
    <div
      v-if="!stringIsNullOrWhitespace(vacancy.Description)"
      class="v-vacancy-single--description v-mb-md"
      v-html="sanitize(vacancy.Description)"
    />
    <div
      v-if="appConfig.VueSettingsPreRun.VacanciesShowResponsibilities"
      class="v-vacancy-single--require v-mb-sm"
    >
      <dt
        class="v-vacancy-single--require-title v-mb-xxs"
        v-html="translate('vacanciesList.responsibilities')"
      />
      <dd
        v-if="stringIsNullOrWhitespace(vacancy.Responsibilities)"
        v-html="translate('vacanciesList.notSpecified')"
      />
      <dd
        v-else
        v-html="sanitize(vacancy.Responsibilities)"
      />
    </div>
    <div
      v-if="appConfig.VueSettingsPreRun.VacanciesShowRequirements"
      class="v-vacancy-single--require v-mb-sm"
    >
      <dt
        class="v-vacancy-single--require-title v-mb-xxs"
        v-html="translate('vacanciesList.requirements')"
      />
      <dd
        v-if="stringIsNullOrWhitespace(vacancy.Requirements)"
        v-html="translate('vacanciesList.notSpecified')"
      />
      <dd
        v-else
        v-html="sanitize(vacancy.Requirements)"
      />
    </div>
    <div
      v-if="appConfig.VueSettingsPreRun.VacanciesShowSchedule"
      class="v-vacancy-single--additional v-d-flex"
      :class="[appConfig.VueSettingsPreRun.VacanciesShowContacts ? 'v-mb-xxs' : 'v-mb-sm']"
    >
      <dt
        class="v-mr-xxs"
        v-html="translate('vacanciesList.workSchedule')"
      />
      <dd
        v-if="stringIsNullOrWhitespace(vacancy.Schedule)"
        v-html="translate('vacanciesList.notSpecified')"
      />
      <dd
        v-else
        v-html="sanitize(vacancy.Schedule)"
      />
    </div>
    <div
      v-if="appConfig.VueSettingsPreRun.VacanciesShowContacts"
      class="v-vacancy-single--additional v-d-flex v-mb-sm"
    >
      <dt
        class="v-mr-xxs"
        v-html="translate('vacanciesList.contacts')"
      />
      <dd
        v-if="stringIsNullOrWhitespace(vacancy.Contacts)"
        v-html="translate('vacanciesList.notSpecified')"
      />
      <address
        v-else
        v-html="sanitize(vacancy.Contacts)"
      />
    </div>
    <div class="v-d-flex v-justify-content-end">
      <arora-button
        v-if="appConfig.VueSettingsPreRun.VacanciesEnableEmploymentForm"
        class-name="v-mr-sm"
        :label="translate('vacanciesList.respond')"
        @click="respondTo"
      />
    </div>
  </dl>
</template>

<script setup lang="ts">
import type { Vacancy } from '~types/pageStore'

import { type GUID, useCommon } from '@arora/common'

const { id } = defineProps<{
  id: GUID
}>()

const pageStore = usePageStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { translate, sanitize } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()

const vacancy = computed<Vacancy | undefined>(() => pageStore.Vacancies.find((v) => v.ID === id))

async function respondTo(): Promise<void> {
  if (stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.VacanciesExternalButtonLink)) {
    await popupStore.closePopup()

    await nextTick()
    pageStore.SelectedVacancyId = id

    const element = document.getElementById('v-vacancy-form')

    if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' })
  } else {
    await navigateTo(appConfig.VueSettingsPreRun.VacanciesExternalButtonLink)
  }
}
</script>

<style lang="scss">
.v-vacancy-single {
  &--title {
    font-size: 1.45rem;
    font-weight: 600;
  }

  &--require {
    &-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    p,
    ul {
      margin: 0;
    }
  }

  &--additional {
    dt {
      font-weight: 600;
    }
  }
}
</style>
